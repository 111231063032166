body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Arimo', 'sans-serif';
  src: url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap');
}

.ant-btn-primary {
  border-color: #16613e !important;
  background: #16613e !important;
}

.photo-comite {
  position: relative;
}

.photo-comite-img {
  width: 150px;
  height: 150px;
}

.cgu-title {
  @apply text-lg font-bold md:text-2xl mt-10 md:mt-16 mb-2 sm:mb-5;
}

.photo-comite::after {
  content: '';
  position: absolute;
  top: 0;
  left: 16px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid #E16262;
}

.cookies-table, .cookies-table tr, .cookies-table td {
  border: 1px solid #000;
  background-color: #fff !important;
}

.cookies-table .highlight {
  background-color: #8AB1AB !important;
}

.cookies-table td {
  padding: 10px;
}

@media screen and (max-width: 450px) {
  .cookies-table td {
    padding: 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 850px) {
  .photo-comite-img {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
  }

  .no-margin-bottom {
    margin-bottom: 0;
  }
  
  .photo-comite::after {
    width: 100px;
    height: 100px;
    border-width: 3px;
    left: 10px;
  }
}