.sd-root-modern:not(.svc-tab-designer) .sd-container-modern__title {
    background-color: #F8F8F8 !important;
}

.sd-root-modern {
    background-color: #F8F8F8 !important;
}

.sd-title.sd-container-modern__title {
    box-shadow: none !important;
}

#sv-nav-next {
    margin-left: auto;
}

.button {
    text-align: center;
    margin: 0 auto;
}

.save {
    background: white;
    border-color: #59ad94;
    border-radius: calc(0.5 * var(--base-unit, 8px));
    color: #59ad94;
    padding: 11px 16px;
    cursor: pointer;
    border-width: 1px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    -webkit-box-shadow: 0 10px 20px -6px rgb(0 0 0 / 12%);
    -moz-box-shadow: 0 10px 20px -6px rgba(0, 0, 0, .12);
    box-shadow: 0 10px 20px -6px rgb(0 0 0 / 12%);
    font-weight: 600;
    font-size: calc(2 * var(--base-unit, 8px));
}

.save-data {
    background: #E16262;
    border-radius: 99px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 15px 50px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    border: 0;
    cursor: pointer;
}

.save-data > .text-on-hover {
    display: none;
    margin-right: 10px;
}

.save-data:hover {
    width: 100%;
    max-width: 400px;
    border-radius: 0 99px 99px 0;
}

.save-data:hover > .text-on-hover {
    display: block;
}

.download {
    background-color: #146356;
}

tbody tr:nth-child(odd) td {
    background-color: #f9f9f9;
}

tbody tr:nth-child(odd) td:first-child {
    background-color: #f9f9f9;
}

.sd-panel .sd-row:first-of-type {
    margin-top: 20px !important;
}

.sd-matrix__text--checked {
    z-index: 1000;
    background-color: var(--primary-light, rgba(25, 179, 148, 0.1)) !important;
    padding: calc(2 * var(--base-unit, 8px));
}

.sd-progress {
    height: 10px !important;
}

.sd-completedpage, .sd-completedpage > p {
    padding: 12px;
    text-align: justify;
}

@media screen and (max-width: 640px) {
    .save-data {
        margin: 15px;
        width: 100%;
        border-radius: 0 99px 99px 0;
    }
    .save-data > .text-on-hover {
        display: block;
    }
}